

















import { Observer } from 'mobx-vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Prop({
    default: () => {
      return {}
    },
  })
  nft
  @Prop({ default: false }) forceAttach
  @Prop({ default: true }) disableCheckbox
  @Prop({ default: null }) selectedNft
  nftCheckbox = null as any

  @Watch('forceAttach', { immediate: true }) valueChange(value) {
    this.nftCheckbox = value
  }
  @Watch('selectedNft') selectedNftChange(value) {
    this.nftCheckbox = value?.id == this.nft._id
  }
}
