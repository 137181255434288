import { render, staticRenderFns } from "./attach-nft-card.vue?vue&type=template&id=3c5be908&scoped=true&"
import script from "./attach-nft-card.vue?vue&type=script&lang=ts&"
export * from "./attach-nft-card.vue?vue&type=script&lang=ts&"
import style0 from "./attach-nft-card.vue?vue&type=style&index=0&id=3c5be908&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c5be908",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VCheckbox,VChip})
